import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { SuccessStatus } from '../../enums';
import { ExternalInvoice } from '../../../models/external-invoice';
import { OpenInvoiceDialogAction } from 'app/redux/actions/settings';
import { State } from 'app/redux/reducers';

@Component({
  standalone: true,
  selector: 'app-invoice-button',
  templateUrl: './invoice-button.component.html',
  styleUrls: ['./invoice-button.component.scss'],
  imports: [MatProgressSpinnerModule, NgIf]
})
export class InvoiceButtonComponent {
  @Input() invoiceProvider: any;
  @Input() status: boolean | SuccessStatus;
  @Input() disabled: boolean;

  @Output() handleClicked = new EventEmitter<ExternalInvoice>();

  SuccessStatus = SuccessStatus;

  constructor(@Inject(Store) private store: Store<State>) { }

  buttonClicked(invoiceProvider: ExternalInvoice) {
    if (!this.disabled) {
      this.store.dispatch(new OpenInvoiceDialogAction(invoiceProvider));
    }
  }
}
