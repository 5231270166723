<div (click)="buttonClicked(invoiceProvider)" class="providerBox" [class.notActive]="disabled"
  [class.active]="!disabled">
  @switch (status) {
    @case (SuccessStatus.Success) {
      <div class="successDiv"></div>
    }
    @case (SuccessStatus.IsLoading) {
      <div class="loadingDiv"></div>
      <mat-spinner [diameter]="30" [strokeWidth]="3" *ngIf="status === SuccessStatus.IsLoading" single-color />
    }
    @case (SuccessStatus.Error) {
      <div class="errorDiv"></div>
    }
  }
  <img src="{{invoiceProvider.imageSrc}}">
</div>
<!-- <div class="subHeader" *ngIf="isMobile" [class.notActive]="disabled" [class.active]="!disabled"> {{invoiceProvider.name}}</div> -->
